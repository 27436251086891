/* eslint react/jsx-props-no-spreading: "off" */
import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { Text } from 'rebass';

import MainPage from '@gatsbystorefront/gatsby-theme-storefront-shopify/src/templates/main/MainPage';
import Layout from '@gatsbystorefront/gatsby-theme-storefront-shopify/src/components/Layout';
import strings from './strings.json';
import ImageGallery from 'react-image-gallery';

const { pageTitleTemplate } = strings;
const images = [
  {
    original: require('../../../../images/mainpic.jpg'),
    thumbnail: require('../../../../images/mainpic.jpg'),
    originalHeight: 600,
    originalWidth: 1000,
  },
  {
    original: require('../../../../images/Slide1.jpg'),
    thumbnail: require('../../../../images/Slide1.jpg'),
    originalHeight: 600,
    originalWidth: 1000,
  },
  {
    original: require('../../../../images/Slide2.jpg'),
    thumbnail: require('../../../../images/Slide2.jpg'),
    originalHeight: 600,
    originalWidth: 600,
  },
  {
    original: require('../../../../images/Slide3.jpg'),
    thumbnail: require('../../../../images/Slide3.jpg'),
    originalHeight: 600,
    originalWidth: 600,
  },
  {
    original: require('../../../../images/Slide4.jpg'),
    thumbnail: require('../../../../images/Slide4.jpg'),
    originalHeight: 600,
    originalWidth: 600,
  },
  {
    original: require('../../../../images/Slide5.jpg'),
    thumbnail: require('../../../../images/Slide5.jpg'),
    originalHeight: 600,
    originalWidth: 600,
  },
  {
    original: require('../../../../images/Slide6.jpg'),
    thumbnail: require('../../../../images/Slide6.jpg'),
    originalHeight: 600,
    originalWidth: 600,
  },
  {
    original: require('../../../../images/Slide7.jpg'),
    thumbnail: require('../../../../images/Slide7.jpg'),
    originalHeight: 600,
    originalWidth: 600,
  },
  {
    original: require('../../../../images/Slide8.jpg'),
    thumbnail: require('../../../../images/Slide8.jpg'),
    originalHeight: 600,
    originalWidth: 600,
  },
  {
    original: require('../../../../images/Slide9.jpg'),
    thumbnail: require('../../../../images/Slide9.jpg'),
    originalHeight: 600,
    originalWidth: 600,
  },
];

export default (props) => {
  const {
    storeName,
    storeDescription,
  } = props.data.store.siteMetadata.gatsbyStorefrontConfig;

  return (
    <Layout>
      <Helmet title={storeName} titleTemplate={pageTitleTemplate} defer={false}>
        <meta name="description" content={storeDescription} />
      </Helmet>
      <ImageGallery
        items={images}
        showFullscreenButton={false}
        showPlayButton={false}
        autoPlay={true}
        showNav={false}
      />
      <div style={{ textAlign: 'center', paddingTop: '5%' }}>
        <Text
          sx={{ fontFamily: 'Great Vibes' }}
          as="span"
          color="#d9b8b4"
          fontSize={['40px', '60px']}
        >
          Explore Our Collections
        </Text>
      </div>
      <MainPage {...props} />
    </Layout>
  );
};

export const mainPageQuery = graphql`
  query MainPageQuery2($handles: [String], $enableWebp: Boolean!) {
    collections: allShopifyCollection(filter: { handle: { in: $handles } }) {
      nodes {
        handle
        title
        description
        fields {
          shopifyThemePath
        }
        image {
          src
          localFile {
            childImageSharp @include(if: $enableWebp) {
              fluid(
                maxWidth: 1300
                maxHeight: 800
                cropFocus: CENTER
                fit: COVER
                background: "white"
              ) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            childImageSharp @skip(if: $enableWebp) {
              fluid(
                maxWidth: 1300
                maxHeight: 800
                cropFocus: CENTER
                fit: COVER
                background: "white"
              ) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }

    products: allShopifyProduct(filter: { handle: { in: $handles } }) {
      nodes {
        title
        description
        handle
        fields {
          shopifyThemePath
          firstImage {
            altText
            localFile {
              childImageSharp @include(if: $enableWebp) {
                fluid(
                  maxWidth: 1300
                  maxHeight: 800
                  cropFocus: CENTER
                  fit: COVER
                  background: "white"
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
              childImageSharp @skip(if: $enableWebp) {
                fluid(
                  maxWidth: 1300
                  maxHeight: 800
                  cropFocus: CENTER
                  fit: COVER
                  background: "white"
                ) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }

    store: site {
      siteMetadata {
        gatsbyStorefrontConfig {
          storeName
          storeDescription
          gatsbyImageProps {
            loading
            fadeIn
            durationFadeIn
          }
        }
      }
    }
  }
`;
