import React from 'react';
import { Flex, Box, Heading } from 'rebass';
import { useStaticQuery, graphql } from 'gatsby';

import MainPageCarousel from './MainPageCarousel';
import MainPageCollectionBlock from './MainPageCollectionBlock';
import MainPageProductBlock from '@gatsbystorefront/gatsby-theme-storefront-shopify/src/templates/main/MainPageProductBlock';

const MainPage = (props) => {
  const dataQuery = useStaticQuery(graphql`
    query MainPageStaticQuery2 {
      site {
        siteMetadata {
          gatsbyStorefrontConfig {
            mainPage {
              handle
              type
              name
              textBgColor
              textColor
              children {
                handle
                type
                name
                textBgColor
                textColor
              }
            }
          }
        }
      }
    }
  `);

  const { mainPage } = dataQuery.site.siteMetadata.gatsbyStorefrontConfig;

  const { data } = props;

  const { gatsbyImageProps } = data.store.siteMetadata.gatsbyStorefrontConfig;

  return (
    <Flex
      flexWrap="wrap"
      px={2}
      pt={3}
      mx="auto"
      style={{
        maxWidth: 1300,
        marginTop: '5%',
      }}
    >
      {mainPage.map((block, index) => {
        if (block.type === 'header') {
          return '';
        } else if (
          block.type === 'collection' &&
          data.collections.nodes.filter(
            (collection) => collection.handle === block.handle
          )[0]
        ) {
          return (
            <Box
              width={1 / 2}
              p={1}
              key={index}
              sx={{ backgroundColor: 'transparent' }}
            >
              <MainPageCollectionBlock
                collection={
                  data.collections.nodes.filter(
                    (collection) => collection.handle === block.handle
                  )[0]
                }
                textColor={block.textColor}
                textBgColor={block.textBgColor}
                gatsbyImageProps={gatsbyImageProps}
              />
            </Box>
          );
        } else if (
          block.type === 'product' &&
          data.products.nodes.filter(
            (product) => product.handle === block.handle
          )[0]
        ) {
          return (
            <Box width={[1, 1 / 2]} p={1} key={index}>
              <MainPageProductBlock
                product={
                  data.products.nodes.filter(
                    (product) => product.handle === block.handle
                  )[0]
                }
                textColor={block.textColor}
                textBgColor={block.textBgColor}
                gatsbyImageProps={gatsbyImageProps}
              />
            </Box>
          );
        } else {
          return '';
        }
      })}
    </Flex>
  );
};

export default MainPage;
