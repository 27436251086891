/** @jsx jsx */
/* eslint no-unused-vars: 0 */
import { Link as GatsbyLink } from 'gatsby';
import { jsx } from 'theme-ui';
import './carousel.css';
import { Box, Image } from 'rebass';

const MainPageCollectionBlock = (props) => {
  const {
    title,
    fields: { shopifyThemePath },
  } = props.collection;
  const { textColor = 'primary' } = props;

  return (
    <GatsbyLink
      to={shopifyThemePath}
      sx={{
        color: textColor,
        textDecoration: 'none',
        ':hover,:focus,.active': {
          color: textColor,
          textDecoration: 'none',
        },
      }}
    >
      <Box m="auto" sx={{ textAlign: 'center', paddingBottom: ['10%', '3%'] }}>
        {title == 'Jewelry' ? (
          <Image src={require('../../../../images/Jewelry.jpg')} />
        ) : title == 'Women’s Sandals' ? (
          <Image src={require('../../../../images/WmSandals.jpg')} />
        ) : title == 'Mink Lashes' ? (
          <Image src={require('../../../../images/Mink.jpg')} />
        ) : title == 'Swimwear' ? (
          <Image src={require('../../../../images/Swimwear.jpg')} />
        ) : null}
      </Box>
    </GatsbyLink>
  );
};

export default MainPageCollectionBlock;
